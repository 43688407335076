import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import Head from "../components/utils/Head"
import loadable from "@loadable/component"

// Load CareerPage using loadable
const CareerPage = loadable(() => import("../components/Career"), {
  fallback: <div className="loading-fallback">Loading...</div>,
})

export default function Careers() {
  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://codefulcrum.com/careers/" />
      </Helmet>
      <Head
        metaTitle="Careers and Job Opportunities - CodeFulcrum"
        title="Careers at CodeFulcrum: Join Our Software Development Team"
        desc="Codefulcrum is hiring! Explore our careers page to discover the latest job openings and embark on a fulfilling career in tech. Apply today and join our talented team of software developers, designers, and project managers."
      />
      <CareerPage />
    </Layout>
  )
}
